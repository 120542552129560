// BEM rules to be used
// Block Element Modifier

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Livvic:wght@100;200;300;400;500;600;700;900&display=swap');

$theme-colors: (
	'primary': #28a745,
	'dark': #000000,
	'dark-1': #160a3d,
	'secondary': #fafafa,
	'sidebarColor': #1e1e1f,
);

.pointer {
	cursor: pointer;
}
.labelText {
	font-weight: 600;
}

$font-weights: 100 200 300 400 500 600 700 800 900;
$font-families: 'Barlow' 'Livvic';
@import 'node_modules/bootstrap/scss/bootstrap';

// Home Page Styling

.homeMain {
	& .home__btn {
		width: 180px;
		border-radius: 50px;
	}
}

//  Company Register Styling

.companyRegisterMain {
	.paswordOuter {
		position: relative;
		.eyeIcon {
			position: absolute;
			top: 3px;
			right: 1px;
		}
	}
	.radioStyle {
		height: 17px;
		width: 17px;
	}
	.labelText {
		font-weight: 600;
	}
	Input:focus {
		box-shadow: none;
	}
	.input-text {
		outline: none;
		font-family: 'Poppins';
		font-size: 18px !important;
		border-radius: 0 !important;
		border: none !important;
		height: 30px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
	}
	.registerBtn {
		text-align: right;
	}
	.formWidth {
		padding: 0px 100px;
	}
}

//Login Page Styling

.loginMain {
	.paswordOuter {
		position: relative;
		.eyeIcon {
			position: absolute;
			top: 17px;
			right: 5px;
		}
	}
	& .h-custom {
		height: calc(100% - 73px);
		& .card {
			box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
			background: #ffffff;
			border: none;
			border-radius: 0.375rem;
		}
		& .login__rounded {
			border-radius: 2rem !important;
			& .radioStyle {
				height: 17px;
				width: 17px;
			}
		}
	}
}

// NavBar Component Styling

.navbarCompMain {
	&.dropdown-menu {
		top: 85% !important;
	}
	& .dropdown-menu.dropdown-menu-right.show {
		position: absolute;
	}
	.active1:focus {
		color: yellowgreen !important;
	}
	#active1 {
		color: yellowgreen !important;
	}
}

// Company Dashboard styling

.cDashboardMain {
	& .card {
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		background: #ffffff;
		border: none;
		border-radius: 0.375rem;
	}
	.icon-shape {
		padding: 10px;
		height: 45px;
		width: 45px;
		text-align: center;
		border-radius: 50%;
	}
}

// New Job Page Styling

.Formcard {
	padding: 0px 10px;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	width: 60%;
	margin: auto;
	border: none;
	border-radius: 0.375rem;
	& .jobdesc {
		outline: none;
		box-shadow: none;
		resize: none;
	}
	& .labelText {
		font-weight: 600;
	}
	& .focushide:focus {
		outline: none;
		box-shadow: none;
	}
}

// MyJobs Page Styling

.myJobsMain {
	& .card {
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		background: #ffffff;
		border: none;
		border-radius: 0.375rem;
	}
}

// Edit Form Modal Styling

.editForm {
	padding: 0px 10px;
	& .labelText {
		font-weight: 600;
	}
	& .focushide:focus {
		outline: none;
		box-shadow: none;
	}
}

// Job Activity Page Styling

.jobActivityMain {
	& .card {
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		background: #ffffff;
		border: none;
		border-radius: 0.375rem;
	}
	& .searchField {
		width: 60%;
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
}

// chat page Styling

.ChatLeft {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	& .header {
		padding: 15px 15px;
		// border-bottom: 1px solid lightgray;

		& p {
			margin: 0px;
		}

		& i {
			border: 1px solid black;
			padding: 5px 10px;
		}
	}
	& .rightBorder {
		border-right: 1px solid lightgray;
	}
	& .Middle {
		border-bottom: 1px solid lightgray;
		border-top: 1px solid lightgray;
		height: 90px;
		& .outerHeader {
			padding: 25px 20px;
			@media (max-width: 600px) {
				padding: 25px 5px;
			}
		}
		& .inputOuter {
			padding: 25px 30px;

			& input {
				border: 1px solid gray;
				padding: 10px;
				border-radius: 5px;
				width: 100%;
			}
		}
	}

	& .chat {
		& .profileOuter {
			border-right: 1px solid lightgray;
			padding: 20px 25px;
			min-height: 400px;

			& .profile {
				& img {
					border-radius: 50% !important;
					height: 55px;
					width: 55px;
				}

				& p {
					margin: 0px;
					font-size: 16px;
					text-align: left;
					padding-left: 15px;
				}
			}
		}
	}
}

// chatViewBody Styling

.chatViewpage {
	padding: 0 0 15px;

	& .msgInner {
		padding-bottom: 1px;
	}
	& .msgMain {
		height: 380px;
		overflow-y: auto;
	}
}
.IconCircle {
	border: 1px solid black;
	padding: 5px 10px;
}
.chatCountCircle {
	background-color: #1e1e1f !important;
	height: 25px;
	color: white;
	width: 25px;
	font-weight: 500;
	border-radius: 50%;
}

// ReceiveChat Styling

.receiveChatOuter {
	display: flex;
	margin: 10px 5px;
	& .Recieve-msg {
		flex-wrap: wrap;
		max-width: 70%;
		padding: 5px 0 0 5px;
	}

	& .Recieve-msg-texts {
		background: #0a53be;
		border-radius: 20px;
		color: white;
		font-size: 15px;
		margin: 0 0 2px;
		overflow-wrap: break-word;
		padding: 6px 12px;
	}
	& .Recieve-msg-time {
		float: left;
		font-size: 12px;
	}
}

// SendChat Styling

.sendChatOuter {
	display: flex;
	justify-content: flex-end;
	margin: 10px 5px;
	& .send-msg {
		flex-wrap: wrap;
		max-width: 70%;
	}
	& .send-msg-texts {
		background: navajowhite;
		border-radius: 20px;
		color: black;
		font-size: 14px;
		margin: 0 0 2px;
		overflow-wrap: break-word;
		padding: 6px 15px 6px 12px;
	}
	& .send-msg-time {
		float: right;
		font-size: 12px;
	}
}

// setting Styling

.SettingMain {
	& .collapseBtnTrue {
		position: absolute;
		left: 225px;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
		border: none;
		font-size: 20px;
		height: 40px;
		background: white;
		border-radius: 83px;
		width: 40px;
	}
	& .collapseBtnFalse {
		position: absolute;
		left: 10px;
		top: 70px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
		border: none;
		font-size: 20px;
		height: 40px;
		background: theme-color('primary');
		border-radius: 83px;
		width: 40px;
	}
	& .sideItems {
		height: calc(100vh - 66px);
		width: 250px;
		@media (max-width: 767.98px) {
			position: absolute;
			z-index: 100;
		}
	}
	& .settingContents {
		min-width: calc(100vw - 250px);
		@media (max-width: 1023.98px) {
			margin-top: 25px;
		}
	}
	.sideLink {
		padding: 0.8rem;
		& .linkItems {
			&:hover {
				text-decoration: none;
			}
		}

		& .itemText {
			color: #fafafa;
		}
		.active {
			color: #28a745 !important;
		}
	}
}

//// change Password Styling

.changePswrdMain {
	& .complaint-card {
		width: 100%;
		background: #ffffff;
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		border-radius: 25px;
	}
	& .complaint-form {
		padding: 30px 80px;
		@media (max-width: 598.98px) {
			padding: 30px 15px;
		}
	}
	& .edit-password-info {
		font-family: 'Poppins';
		font-style: normal;
		// font-weight: 600;
		font-size: 18px;
		line-height: 37px;
		color: black;
		border: none !important;
	}
	Input:focus {
		box-shadow: none;
		border: 1px solid #ced4da !important;
	}
	.paswordChangeOuter {
		position: relative;
		.eyeIcon {
			position: absolute;
			top: 12px;
			right: 8px;
		}
	}
}

// .jobCardSet {
// 	display: flex;
// 	min-height: 310px;
// 	flex-direction: column;
// 	justify-content: space-between;
// }

// Job Card Main

.jobCardMain {
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	margin-top: 10px;
}

// Designate Alma Matter Page Styling

.designateAlmaMain {
	.card {
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		background: #ffffff;
		border: none;
		border-radius: 0.375rem;
	}
	.avatar {
		border-radius: 50%;
		height: 100px;
		width: 100px;
	}
	& Input:focus {
		box-shadow: none;
	}
}

// Individual Dashboard Styling Start

// Individual Dashboard Seach

.searchJobCard {
	padding: 0px 10px;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border: none;
	border-radius: 0.375rem;
	margin-top: 10px;
	& .jobdesc {
		outline: none;
		box-shadow: none;
		resize: none;
	}
	& .labelText {
		font-weight: 600;
	}
	& .focushide:focus {
		outline: none;
		box-shadow: none;
	}
}

// Multi Range Slider Styling

.multiRangerSliderMain {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 15px;
	.slider {
		position: relative;
		width: 100%;
	}
	.slider__track,
	.slider__range,
	.slider__left-value,
	.slider__right-value {
		position: absolute;
	}
	.slider__track,
	.slider__range {
		border-radius: 3px;
		height: 5px;
	}
	.slider__track {
		background-color: #28a745;
		width: 100%;
		z-index: 1;
	}
	.slider__range {
		background-color: #28a745;
		z-index: 2;
	}
	.slider__left-value,
	.slider__right-value {
		color: black;
		font-size: 15px;
		margin-top: 12px;
	}
	.slider__left-value {
		left: -10px;
	}
	.slider__right-value {
		right: -15px;
	}
	/* Removing the default appearance */
	.thumb,
	.thumb::-webkit-slider-thumb {
		-webkit-appearance: none;
		-webkit-tap-highlight-color: transparent;
	}
	.thumb {
		pointer-events: none;
		position: absolute;
		height: 0;
		width: 100%;
		outline: none;
	}
	.thumb--left {
		z-index: 3;
		left: 15px;
	}
	.thumb--right {
		z-index: 4;
		right: 15px;
	}

	/* For Chrome browsers */
	.thumb::-webkit-slider-thumb {
		background-color: #f1f5f7;
		border: none;
		border-radius: 50%;
		box-shadow: 0 0 1px 1px #ced4da;
		cursor: pointer;
		height: 18px;
		width: 18px;
		margin-top: 4px;
		pointer-events: all;
		position: relative;
	}

	/* For Firefox browsers */
	.thumb::-moz-range-thumb {
		background-color: #f1f5f7;
		border: none;
		border-radius: 50%;
		box-shadow: 0 0 1px 1px #ced4da;
		cursor: pointer;
		height: 18px;
		width: 18px;
		margin-top: 4px;
		pointer-events: all;
		position: relative;
	}
}

// individual Setting page Styling

.IndividualSettingMain {
	& .collapseBtnTrue {
		position: absolute;
		left: 225px;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
		border: none;
		font-size: 20px;
		height: 40px;
		background: white;
		border-radius: 83px;
		width: 40px;
	}
	& .collapseBtnFalse {
		position: absolute;
		left: 10px;
		top: 70px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
		border: none;
		font-size: 20px;
		height: 40px;
		background: theme-color('primary');
		border-radius: 83px;
		width: 40px;
	}
	& .sideItems {
		height: calc(100vh - 66px);
		width: 250px;
		@media (max-width: 767.98px) {
			position: absolute;
			z-index: 100;
		}
	}
	& .settingContents {
		min-width: calc(100vw - 250px);
		@media (max-width: 1023.98px) {
			margin-top: 25px;
		}
	}
	.sideLink {
		padding: 0.8rem;
		& .linkItems {
			&:hover {
				text-decoration: none;
			}
		}

		& .itemText {
			color: #fafafa;
		}
		.active {
			color: #28a745 !important;
		}
	}
}

// Update Profile Page Styling

.updateProfileMain {
	.complaint-card {
		width: 100%;
		background: #ffffff;
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		border-radius: 25px;
	}
	.complaint-form {
		padding: 7px 30px;
		@media (max-width: 607px) {
			padding: 30px;
		}
	}
	.profile-form {
		padding: 20px 30px;
		@media (max-width: 607px) {
			padding: 10px 15px;
			.custom-input {
				width: 100% !important;
			}
		}
	}
	.edit-password-info {
		font-family: 'Poppins';
		font-style: normal;
		// font-weight: 600;
		font-size: 18px;
		line-height: 37px;
		// color: #9f9f9f;
		border: none !important;
	}
	.custom-input {
		border: none !important;
		font-family: 'Poppins';
		font-size: 18px !important;
		border-radius: 0px !important;
		border-bottom: 1.5px solid #d0d0d0 !important;
		// width: 75% !important;
		& ::placeholder {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 27px;
			color: #9f9f9f;
		}
		@media (max-width: 607px) {
			width: 100% !important;
		}
	}
	.auth-label {
		font-weight: 600;
		color: #000000;
	}
}

/* Donate Page Styling */

.donateOuterMain {
	.auth-button-paypal {
		border-radius: 100px !important;
		background-color: #2e7bf6 !important;
		height: 60px !important;
		border: none !important;
		color: #fff !important;
		width: 75% !important;
	}
	.request-form-input {
		border: none !important;
		font-family: 'Poppins';
		border-radius: 0px !important;
		color: #000000 !important;
		border-bottom: 1.5px solid #000000 !important;
		width: 75% !important;
		&:focus {
			box-shadow: none;
			border-bottom: 1.5px solid #000000 !important;
		}
	}
}

/* Start Media Query Styling */

@media screen and (min-width: 768px) {
	.homeMain {
		& .individual-btn-responsive {
			text-align: right;
		}
	}
}
@media screen and (max-width: 585px) {
	.homeMain {
		& .btn-responsive {
			text-align: center;
		}
	}
}
@media (max-width: 450px) {
	.loginMain {
		& .h-custom {
			height: 100%;
		}
	}
}

@media (min-width: 600px) and (max-width: 991.98px) {
	.companyRegisterMain {
		.registerBtn {
			text-align: center !important;
		}
		.formWidth {
			padding: 0px 20px !important;
		}
	}
}
@media (max-width: 599.99px) {
	.companyRegisterMain {
		.formWidth {
			padding: 0px 20px !important;
		}
	}
}

/* Dev-zuki-code- */

@media (max-width: 575px) {
	.navbar-nav {
		flex-direction: column;
		justify-content: space-around;
	}

	.Formcard {
		width: 100%;
	}
	.jobActivityMain {
		& .searchField {
			width: 100%;
		}
	}
}
@media (min-width: 576px) and (max-width: 991px) {
	.navbar-nav {
		flex-direction: row;
		justify-content: space-around;
	}
	.jobActivityMain {
		& .searchField {
			width: 90%;
		}
	}
}
@media (min-width: 992px) {
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}
.custom-input {
	border-width: 0px 0px 1px;
	border-color: #dadada;
	font-size: 14px;
	text-align: left;
	letter-spacing: 0px;
	color: #323a47;
	padding-bottom: 5px;
}
.HomePic {
	position: relative;

	// z-index: -1;
	&_Button {
		z-index: 3;
		margin-bottom: -13%;
		margin-left: 53%;
		&:hover {
			cursor: pointer !important;
		}
	}
}
